<template>
  <b-row>
    <b-col cols="12" xl="3" class="nopadding">
      <b-card>
        <div class="row">
          <div class="col bg-secondary">
            <b-form-group>
              <label for="txt_name">{{$t("common.name")}}</label>
              <div v-if="$v.staff.name.$error" class="pull-right">
                  <div class="text-danger" v-if="!$v.staff.name.required">{{$t("common.name_required")}}.</div>
                  <div class="text-danger" v-else-if="!$v.staff.name.isUnique">{{$t("common.name_unique")}}</div>
              </div>
              <b-form-input type="text" id="txt_name" :placeholder="$t('common.placeholder_name')"
              v-model="staff.name" maxlength="50" ref="txt_name" size="sm"
              v-on:input="$v.staff.name.$touch"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_staff_phone">{{$t('common.phone')}}</label>
              <div v-if="$v.staff.phone.$error" class="pull-right">
                <div class="text-danger" v-if="!$v.staff.phone.required">{{$t("common.phone_required")}}.</div>
                <div class="text-danger" v-else-if="!$v.staff.phone.numeric">{{$t('common.phone_number_only')}}</div>
                <div class="text-danger" v-else-if="!$v.staff.phone.minLength">{{$t('common.phone_ten_number')}}</div>
              </div>
              <b-form-input type="text" id="txt_staff_phone" size="sm"
              v-model.trim="staff.phone" maxlength="20"
              v-on:input="$v.staff.phone.$touch"
              :placeholder="$t('common.placeholder_phone')"></b-form-input>
            </b-form-group>
            <b-form-group>
              <label for="txt_address">{{$t("common.address")}}</label>
              <b-form-textarea type="text" id="txt_address"
              v-model="staff.address" maxlength="200"
              :placeholder="$t('common.placeholder_address')"></b-form-textarea>
            </b-form-group>
          </div>
        </div>
        <div class="row pull-right mt-2">
          <b-button variant="outline-success" class="mr-1"
          @click.prevent="clear()"><i class="fa fa-refresh"></i> {{$t("common.refresh")}}</b-button>
          <b-button variant="outline-primary" @click.prevent="saveStaff()"><span class="icon is-small">
              <i class="fa fa-check"></i> {{$t('common.button.save')}}</span></b-button>
        </div>
      </b-card>
    </b-col>
    <b-col cols="12" xl="9" class="nopadding">
      <transition name="slide">
      <b-card>
        <div slot="header"><i class="fa fa-list" aria-hidden="true"></i> <strong>{{$t("common.staff_table")}}</strong></div>
        <b-form-group label-for="table-style-variant" label-cols-lg="2">
          <div class="input-group">
            <input type="text" class="form-control" :placeholder="$t('common.search_text')"
            v-model="filter" maxlenght="50" @keyup.enter="apply_filter">
            <div class="input-group-append">
                <button class="btn btn-outline-secondary" @click="apply_filter"
                type="button">{{$t("common.search")}}</button>
            </div>
          </div>
        </b-form-group>
        <b-table striped hover responsive :tbody-tr-class="rowDeleted"
        :items="filteredAndSortedData" :fields="fields" :current-page="page"
        :per-page="0" @row-clicked="rowClicked">
          <template slot="top-row" slot-scope="{ fields }">
            <td v-for="field in fields" :key="field.key">
              <input v-model="filteredAndSortedData[field.key]" 
              v-if="field.key && field.label" :placeholder="field.label">
            </td>
          </template>
           <template slot="branch" slot-scope="data">
            <label v-if="data.item.branch">{{data.item.branch.name}}</label>
            <label v-else-if="data.item.company">{{data.item.company.name}}</label>
            <label v-else>System</label>
          </template>
          <template slot="updated_at" slot-scope="data">
            <label v-if="data.item.updated_at">{{VnDateTimeFormat(data.item.updated_at)}}</label>
            <label v-else></label>
          </template>
          <template slot="created_at" slot-scope="data">
            <label>{{VnDateTimeFormat(data.item.created_at)}}</label>
          </template>
          <template slot="status" slot-scope="data">
            <b-badge :variant="getBadge(data.item.status)">{{getObjectStatus(data.item.status)}}</b-badge>
          </template>
          <template slot="action" slot-scope="data">
            <b-button-group size="sm">
              <b-button variant="outline-danger"
                @click="confirmDelete(data.item)">
                <i class="fa fa-trash"></i> {{$t("common.delete")}}
              </b-button>
            </b-button-group>
          </template>
        </b-table>
        <template slot="footer">
            <b-row class="pull-right">
              <b-col>
                <nav>
                  <pager :frame="8"
                        :pageCount="page_count"
                        :page="page"
                        @change="change_page">
                  </pager>
                </nav>
              </b-col>
            </b-row>
          </template>
      </b-card>
      </transition>
    </b-col>
  </b-row>
</template>
<style scoped>
  label{
    margin-bottom: 0rem;
  }
</style>
<script>
import { validationMixin } from 'vuelidate'
import { required, minLength,maxLength,numeric }
from 'vuelidate/lib/validators'
import Pager from '../Pager'
import { mapGetters, mapActions } from 'vuex'
import '../../../node_modules/vue-toastr-2/dist/vue-toastr-2.min.css'
import { Role } from '@/helpers/role.js'
import { Business } from '../../helpers/business'

export default {
  name: 'Staff',
  mixins: [validationMixin],
  components: {Pager},
  props: {
    hover: {
      type: Boolean,
      default: true
    }
  },
  data: () => {
    return {
      processing:false,
      staff:{
        id:null,
        business:null,
        company_id:null,
        branch_id:null,
        user_create_id:null,
        user_update_id:null,
        name:null,
        address:null,
        type:0,
        phone:null
      }
    }
  },
  validations: {
    staff: {
      name:{
        required,
        maxLength:maxLength(50)
      },
      phone:{
        required,
        numeric,
        minLength:minLength(10)
      }
    }
  },
  computed: {
    ...mapGetters('st_staff', ['page_count','page','filter','per_page']),
    fields(){
      let fields=[]
      let user=this.$store.state.st_authentication.user
      if(user.role==Role.Super_Admin){
        fields.push({key: 'branch',label:this.$t("common.company"),sortable: true})
        fields.push({key: 'business',label:this.$t("common.business"),sortable: true})
      }

      if(user.role==Role.Admin && user.company.cocon){
        fields.push({key: 'branch',label:this.$t("common.company_branch"),sortable: true, variant:'info'})
      }
      fields.push({key: 'name',label:this.$t("common.name"),sortable: true, variant:'primary'})
      fields.push({key: 'phone',label:this.$t("common.phone"),sortable: true})
      fields.push({key: 'address',label:this.$t("common.address"),sortable: true})
      fields.push({key: 'created_at',label:this.$t("common.date"),sortable: true})
      fields.push({key: 'status',label:this.$t("common.status"),sortable: true})

      if(user.role==Role.Super_Admin||user.role==Role.Admin||user.role==Role.Branch_Admin){
        fields.push({key: 'action',label:'',sortable: false})
      }

      return fields
    },
    user_login() {
      return this.$store.state.st_authentication.user
    },
    filter:{
      get: function(){
        return this.$store.state.st_staff.filter
      },
      set: function(text){
        this.$store.commit('st_staff/set_filter',text)
      }
    },
    type:{
      get: function(){
        return this.$store.state.st_staff.type
      },
      set: function(val){
        this.$store.commit('st_staff/set_type',val)
      }
    },
    filteredAndSortedData:{
      get: function(){
        let vm=this
        let result = vm.$store.state.st_staff.ls_staff
        if (vm.filter) {
            result = result.filter(item =>vm.searchLike(item))
        }

        return result
      }
    }
  },
  methods: {
    rowDeleted(item, type){
      if (!item || type !== 'row') return
      if (item.status === -1) return 'table-danger'
    },
    ...mapActions('st_staff', ['get_ls_staff','change_page', 'apply_filter']),
    clear(){
      this.staff={id:null,address:null,business:null,company_id:null,branch_id:null,
      name:null,user_create_id:null,user_update_id:null,phone:null,type:0}

      this.$refs.txt_name.$el.focus()
      this.$v.$reset()
    },
    _validations () {
      return new Promise(resolve => {
        if (this.$v.$error || !this.$v.$pending) {
            return resolve()
        }
        let poll = setInterval(() => {
        if (!this.$v.$pending) {
            clearInterval(poll)
            resolve()
        }
        }, 200)
      })
    },
    async isValid () {
      this.$v.$reset()
      this.$v.$touch()
      await this._validations()
      return Promise.resolve(!this.$v.$error)
    },

    async saveStaff() {
      const isValid = await this.isValid()
      if (!isValid) {
        return
      }

      var app = this
      if (app.processing == true) {
        debugger
        return
      }

      // set the async state
      app.processing = true

      if(app.staff.id){
        app.$store.dispatch('st_staff/update',{lang:app.$i18n.locale,data:
        {id:app.staff.id,name:app.staff.name,
        user_update_id:app.user_login.id,phone:app.staff.phone}})
        .then(function (resp) {
          app.get_ls_staff()
          app.clear()
          app.$toastr.success(app.$t("common.created_success"),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }else{
        debugger
        app.staff.user_create_id=app.user_login.id
        app.staff.company_id=app.user_login.company_id
        app.staff.branch_id=app.user_login.branch_id
        app.staff.business=app.user_login.company.business
        app.staff.type=0

        app.$store.dispatch('st_staff/create',app.staff)
        .then(function (resp) {
          app.get_ls_staff()
          app.clear()
          app.$toastr.success(app.$t('common.created_success'),app.$t("common.message"))
          app.processing = false
        })
        .catch(function (resp) {
          app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
          app.processing = false
        })
      }
      app.processing = false
    },
    getRowCount (items) {
      return items.length
    },
    rowClicked (item) {
      if(!item) return
      this.staff=item
    },
    searchLike:function(item){
      return item.name&&item.name.includes(this.filter)
      ||item.created_at.includes(this.filter)
      ||item.business.includes(this.filter)
      ||item.address&&item.address.includes(this.filter)
      ||item.branch&&item.branch.name.includes(this.filter)
      ||item.company&&item.company.name.includes(this.filter)
    },
    confirmDelete(obj) {
      debugger
      this.$bvModal.msgBoxConfirm(this.$t("common.delete_confirm"), {
        title: this.$t("common.confirm_delete"),
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: this.$t("common.delete"),
        cancelTitle: this.$t("common.cancel"),
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: false
      })
      .then(value => {
        if(value!=true) return
        this.deleteObj(obj)
      })
      .catch(err => {
        // An error occurred
        this.$toastr.error(this.$t("common.error_message"),this.$t("common.message"))
      })
    },
    async recoverObj(obj){
      let app=this
      if(obj.status>=0) return
      let co=await this.$store.dispatch('st_staff/recover_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_staff()
        app.$toastr.success(app.$t("common.recovered_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    },
    async deleteObj(obj) {
      let app=this
      debugger
      if(obj.status<0) return
      if(obj.status==1){
        app.$toastr.info(app.$t("common.item_using_message",{obj:app.$t("common.staff")}),app.$t("common.message"))
        return
      }
      let co=await this.$store.dispatch('st_staff/delete_obj',obj.id)
      if(co && co.data.ok){
        app.get_ls_staff()
        app.$toastr.success(app.$t("common.deleted_success"),app.$t("common.message"))
      }else{
        app.$toastr.error(app.$t("common.error_message"),app.$t("common.message"))
      }
    }
  },
  async mounted () {
    let vm=this
    if(vm.user_login.company.business==Business.vlxd){
      vm.type=0
    }else{
      vm.type=1
    }
    vm.$refs.txt_name.$el.focus()
    vm.get_ls_staff()
  }
}
</script>
